import { defineCustomElement } from '@tylertech/forge-core';
import { AppLauncherComponent } from './app-launcher';

export * from './app-launcher-button';
export * from './app-launcher-adapter';
export * from './app-launcher-constants';
export * from './app-launcher-core';
export * from './app-launcher';

export function defineAppLauncherComponent(): void {
  defineCustomElement(AppLauncherComponent);
}

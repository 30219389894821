import { COMPONENT_NAME_PREFIX } from '../../constants';

const elementName = `${COMPONENT_NAME_PREFIX}footer`;

const attributes = {
  LAYOUT_BREAKPOINT: 'layout-breakpoint',
  LAYOUT: 'layout'
};

const classes = {
  FOOTER: 'forge-footer',
  ALTERNATIVE_LAYOUT: 'forge-footer--alternative'
};

const selectors = {
  FOOTER: `.${classes.FOOTER}`
};

const strings = {
  DEFAULT_LAYOUT: 'auto',
  ROLE: 'contentinfo'
};

const numbers = {
  LAYOUT_BREAKPOINT: 900
};

export const FOOTER_CONSTANTS = {
  elementName,
  attributes,
  classes,
  selectors,
  strings,
  numbers
};

export type FooterLayout = 'standard' | 'alternative' | 'auto';

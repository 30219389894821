import { isFunction } from '@tylertech/forge-core';
import { IAppLauncherButtonAdapter } from './app-launcher-button-adapter';
import { IAppLauncherConfig } from './app-launcher-button-constants';
import { IAppLauncherCore } from '../app-launcher-core';
import { AppLauncherOptionsCallback } from '../app-launcher-constants';

export interface IAppLauncherButtonCore extends IAppLauncherCore {}

export class AppLauncherButtonCore implements IAppLauncherButtonCore {
  private _optionsCallback: AppLauncherOptionsCallback;
  private _isOpen = false;
  private _clickListener: (evt: MouseEvent) => void;
  private _dismissListener: () => void;
  private _keydownListener: (evt: KeyboardEvent) => void;
  private _cancelDismissListener: () => void;

  constructor(private _adapter: IAppLauncherButtonAdapter) {
    this._clickListener = evt => this._onClick(evt);
    this._dismissListener = () => this._onDismiss();
    this._keydownListener = evt => this._onKeydown(evt);
  }

  public initialize(): void {
    this._adapter.initialize();
    this._adapter.setClickListener(this._clickListener);
  }

  public disconnect(): void {
    if (this._isOpen) {
      this._close();
    }
    this._adapter.removeWindowListener('keydown', this._keydownListener);
    this._adapter.removeClickListener(this._clickListener);
  }

  public focusFirst(): void {
    if (this._isOpen) {
      this._adapter.setAppLauncherFocus();
    }
  }

  private _onClick(evt: MouseEvent): void {
    if (!this._isOpen) {
      this._open();
    } else {
      this._close();
    }
  }

  private _onKeydown(evt: KeyboardEvent): void {
    if (evt.key === 'Escape' || evt.keyCode === 27) {
      this._close();
    } else if (evt.key === 'Tab' || evt.keyCode === 9) {
      evt.preventDefault();
      this._adapter.removeWindowListener('keydown', this._keydownListener);
      this._adapter.setAppLauncherFocus();
    }
  }

  private _onDismiss(): void {
    this._close();
  }

  private _open(): void {
    if (isFunction(this._cancelDismissListener)) {
      this._cancelDismissListener();
    }

    const appLauncherConfig: IAppLauncherConfig = {
      optionsCallback: this._optionsCallback
    };
    this._cancelDismissListener = this._adapter.open(appLauncherConfig, this._dismissListener);
    this._adapter.addWindowListener('keydown', this._keydownListener);
    this._isOpen = true;
  }

  private _close(): void {
    if (isFunction(this._cancelDismissListener)) {
      this._cancelDismissListener();
    }
    this._adapter.removeWindowListener('keydown', this._keydownListener);
    this._adapter.close();
    this._adapter.requestFocus();
    this._isOpen = false;
  }

  public get optionsCallback(): AppLauncherOptionsCallback {
    return this._optionsCallback;
  }
  public set optionsCallback(cb: AppLauncherOptionsCallback) {
    this._optionsCallback = cb;
  }
}

import { customElement, attachLightTemplate, coreProperty } from '@tylertech/forge-core';
import { tylIconApps } from '@tylertech/tyler-icons/standard';
import { APP_LAUNCHER_BUTTON_CONSTANTS } from './app-launcher-button-constants';
import { AppLauncherButtonCore } from './app-launcher-button-core';
import { AppLauncherButtonAdapter } from './app-launcher-button-adapter';
import { IAppLauncherComponent, AppLauncherComponent } from '../app-launcher';
import { AppLauncherOptionsCallback } from '../app-launcher-constants';
import {
  BaseComponent,
  IconComponent,
  IconRegistry,
  PopoverComponent,
  TooltipComponent,
  IconButtonComponent
} from '@tylertech/forge';

const template = '<template><forge-icon-button aria-label=\"Open app launcher\"><forge-icon name=\"apps\"></forge-icon></forge-icon-button><forge-tooltip delay=\"500\" placement=\"bottom\">App launcher</forge-tooltip></template>';

export interface IAppLauncherButtonComponent extends IAppLauncherComponent {}

declare global {
  interface HTMLElementTagNameMap {
    'forge-app-launcher-button': IAppLauncherButtonComponent;
  }
}

/**
 * Provides a common mechanism for accessing the `forge-app-launcher` within a popup, along with a common icon to use within the app-bar.
 * 
 * @tag forge-app-launcher-button
 */
@customElement({
  name: APP_LAUNCHER_BUTTON_CONSTANTS.elementName,
  dependencies: [
    PopoverComponent,
    AppLauncherComponent,
    IconButtonComponent,
    TooltipComponent,
    IconComponent
  ]
})
export class AppLauncherButtonComponent extends BaseComponent implements IAppLauncherButtonComponent {
  private _core: AppLauncherButtonCore;

  constructor() {
    super();
    IconRegistry.define(tylIconApps);
    this._core = new AppLauncherButtonCore(new AppLauncherButtonAdapter(this));
  }

  public initializedCallback(): void {
    attachLightTemplate(this, template);
  }

  public connectedCallback(): void {
    this._core.initialize();
  }

  public disconnectedCallback(): void {
    this._core.disconnect();
  }

  @coreProperty()
  public optionsCallback: AppLauncherOptionsCallback;

  public focusFirst(): void {
    this._core.focusFirst();
  }
}

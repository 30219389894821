import { addClass, getShadowElement, removeClass, removeElement, toggleClass } from '@tylertech/forge-core';
import { BaseAdapter, IBaseAdapter } from '@tylertech/forge';
import { ILandingPageLayoutComponent } from './landing-page-layout';
import { LandingPageLayoutAlignment, LandingPageLayoutMode, LandingPageLayoutSize, LANDING_PAGE_LAYOUT_CONSTANTS } from './landing-page-layout-constants';

export interface ILandingPageLayoutAdapter extends IBaseAdapter {
  setSize(size: LandingPageLayoutSize): void;
  setAlignment(alignment: LandingPageLayoutAlignment): void;
  initialize(): void;
  setMode(mode: LandingPageLayoutMode): void;
  addTopSlotListener(event: string, listener: EventListener): void;
  addAnnouncementsSlotListener(event: string, listener: EventListener): void;
  removeAnnouncementsSlotListener(event: string, listener: EventListener): void;
  removeTopSlotListener(event: string, listener: EventListener): void;
  toggleBodyOnly(hasTopElements: boolean): void;
  toggleHeaderAlignment(hasAnnouncements: boolean): void;
  setSmallImageSource(url: string): void;
  setLargeImageSource(url: string): void;
}

export class LandingPageLayoutAdapter extends BaseAdapter<ILandingPageLayoutComponent> implements ILandingPageLayoutAdapter {
  private _imageElement: HTMLImageElement | undefined;
  private _viewBodyElement: HTMLElement;
  private _topSlotElement: HTMLSlotElement;
  private _AnnouncementsSlotElement: HTMLSlotElement;
  private _rootElement: HTMLElement;
  private _headerBody: HTMLElement;
  private _backgroundPicture: HTMLPictureElement;

  constructor(_component: ILandingPageLayoutComponent) {
    super(_component);
    this._viewBodyElement = getShadowElement(_component, LANDING_PAGE_LAYOUT_CONSTANTS.selectors.VIEW_BODY) as HTMLElement;
    this._topSlotElement = getShadowElement(_component, LANDING_PAGE_LAYOUT_CONSTANTS.selectors.TOP_SLOT) as HTMLSlotElement;
    this._AnnouncementsSlotElement = getShadowElement(_component, LANDING_PAGE_LAYOUT_CONSTANTS.selectors.ANNOUNCEMENTS_SLOT) as HTMLSlotElement;
    this._rootElement = getShadowElement(_component, LANDING_PAGE_LAYOUT_CONSTANTS.selectors.component) as HTMLSlotElement;
    this._headerBody = getShadowElement(_component, LANDING_PAGE_LAYOUT_CONSTANTS.selectors.HEADER_BODY) as HTMLSlotElement;
    this._backgroundPicture = getShadowElement(_component, LANDING_PAGE_LAYOUT_CONSTANTS.selectors.BACKROUND_PICTURE) as HTMLPictureElement;

    // Initialize the component without any announcements
    addClass(LANDING_PAGE_LAYOUT_CONSTANTS.classes.EMPTY_ANNOUNCEMENTS, this._headerBody);
  }
  public setSize(size: LandingPageLayoutSize): void {
    removeClass(LANDING_PAGE_LAYOUT_CONSTANTS.classes.WIDE_WIDTH_MODE, this._rootElement);

    switch (size) {
      case 'wide':
        addClass(LANDING_PAGE_LAYOUT_CONSTANTS.classes.WIDE_WIDTH_MODE, this._rootElement);
        break;
    }
  }

  public initialize(): void {
    this._setupImage();
  }

  public setMode(mode: LandingPageLayoutMode): void {
    this._viewBodyElement.classList.remove(LANDING_PAGE_LAYOUT_CONSTANTS.classes.LAYOUT_BODY_EQUAL);
    this._viewBodyElement.classList.remove(LANDING_PAGE_LAYOUT_CONSTANTS.classes.LAYOUT_BODY_THREE);
    this._viewBodyElement.classList.remove(LANDING_PAGE_LAYOUT_CONSTANTS.classes.LAYOUT_BODY_TWO_THIRD);
    this._viewBodyElement.classList.remove(LANDING_PAGE_LAYOUT_CONSTANTS.classes.LAYOUT_BODY_SINGLE);
    switch (mode) {
      case 'two-third':
        this._viewBodyElement.classList.add(LANDING_PAGE_LAYOUT_CONSTANTS.classes.LAYOUT_BODY_TWO_THIRD);
        break;
      case 'equal':
        this._viewBodyElement.classList.add(LANDING_PAGE_LAYOUT_CONSTANTS.classes.LAYOUT_BODY_EQUAL);
        break;
      case 'three':
        this._viewBodyElement.classList.add(LANDING_PAGE_LAYOUT_CONSTANTS.classes.LAYOUT_BODY_THREE);
        break;
      case 'single':
        this._viewBodyElement.classList.add(LANDING_PAGE_LAYOUT_CONSTANTS.classes.LAYOUT_BODY_SINGLE);
        break;
      default:
        this._viewBodyElement.classList.add(LANDING_PAGE_LAYOUT_CONSTANTS.classes.LAYOUT_BODY_TWO_THIRD);
    }
  }

  public addTopSlotListener(event: string, listener: EventListener): void {
    this._topSlotElement.addEventListener(event, listener);
  }

  public removeTopSlotListener(event: string, listener: EventListener): void {
    this._topSlotElement.removeEventListener(event, listener);
  }

  public addAnnouncementsSlotListener(event: string, listener: EventListener): void {
    this._AnnouncementsSlotElement.addEventListener(event, listener);
  }

  public removeAnnouncementsSlotListener(event: string, listener: EventListener): void {
    this._AnnouncementsSlotElement.removeEventListener(event, listener);
  }

  public toggleBodyOnly(hasTopElements: boolean): void {
    toggleClass(this._viewBodyElement, !hasTopElements, LANDING_PAGE_LAYOUT_CONSTANTS.classes.LAYOUT_BODY_ONLY);
  }

  public setSmallImageSource(url: string): void {
    const source = document.createElement('source');
    source.srcset = url;
    source.className = LANDING_PAGE_LAYOUT_CONSTANTS.classes.BACKROUND_PICTURE_IMAGE_SMALL;
    source.media = LANDING_PAGE_LAYOUT_CONSTANTS.strings.MOBILE_IMG_MEDIA;

    this._backgroundPicture.appendChild(source);
    this._refreshImageSources();
  }

  public setLargeImageSource(url: string): void {
    const source = document.createElement('source');
    source.srcset = url;
    source.className = LANDING_PAGE_LAYOUT_CONSTANTS.classes.BACKROUND_PICTURE_IMAGE_LARGE;
    source.media = LANDING_PAGE_LAYOUT_CONSTANTS.strings.DESKTOP_IMG_MEDIA;

    this._backgroundPicture.appendChild(source);
    this._refreshImageSources();
  }

  private _refreshImageSources(): void {
    // the img element needs to be "reset" when new sources are added/removed
    const imgElement = this._backgroundPicture.querySelector('img');
    if (imgElement) {
      removeElement(imgElement);
    }

    this._backgroundPicture.appendChild(document.createElement('img'));
  }

  public setAlignment(alignment: LandingPageLayoutAlignment): void {
    removeClass(LANDING_PAGE_LAYOUT_CONSTANTS.classes.LAYOUT_ALIGNMENT_CENTER, this._rootElement);
    removeClass(LANDING_PAGE_LAYOUT_CONSTANTS.classes.LAYOUT_ALIGNMENT_LEFT, this._rootElement);

    switch (alignment) {
      case 'center':
        addClass(LANDING_PAGE_LAYOUT_CONSTANTS.classes.LAYOUT_ALIGNMENT_CENTER, this._rootElement);
        break;
      case 'left':
      default:
        addClass(LANDING_PAGE_LAYOUT_CONSTANTS.classes.LAYOUT_ALIGNMENT_LEFT, this._rootElement);
        break;
    }
  }

  public toggleHeaderAlignment(hasAnnouncements: boolean): void {
    toggleClass(this._headerBody, !hasAnnouncements, LANDING_PAGE_LAYOUT_CONSTANTS.classes.EMPTY_ANNOUNCEMENTS);
  }

  private _setupImage(): void {
    this._imageElement = this._component.querySelector(':scope > img') as HTMLImageElement;
    if (this._imageElement && !this._imageElement.hasAttribute('slot')) {
      this._imageElement.setAttribute('slot', 'image');
    }
  }
}

import { COMPONENT_NAME_PREFIX } from '../constants';

const elementName = `${COMPONENT_NAME_PREFIX}app-launcher`;

const classes = {
  GRID_ITEM_TITLE: 'forge-app-launcher__grid-item-title',
  GRID_ITEM: 'forge-app-launcher__grid-item'
};

const selectors = {
  ROOT: '.forge-app-launcher',
  OPTIONS_CONTAINER: '#forge-app-launcher-options',
  GRID: '.forge-app-launcher__grid',
  GRID_ITEM: `.${classes.GRID_ITEM}`,
  SEARCH_CONTAINER: '.forge-app-launcher__search-container',
  SEARCH_INPUT: 'input#app-launcher-search-input',
  TITLE: '#app-launcher-apps-title'
};

const events = {
  SELECT: `${elementName}-select`
};

const strings = {
  DEFAULT_FONT_ICON_NAME: 'exit_to_app',
  DEFAULT_OPEN_TARGET: '_self'
};

const numbers = {
  MIN_OPTIONS_FOR_SEARCH: 16
};

export const APP_LAUNCHER_CONSTANTS = {
  elementName,
  classes,
  selectors,
  events,
  strings,
  numbers
};

export enum AppLauncherView {
  Loading,
  Default,
  Empty,
  Error
}

export type IconType = 'svg' | 'image' | 'font' | '';

export type AppLauncherOptionsCallback = () => Promise<IAppLauncherOptionResult>;

export interface IAppLauncherOptionResult {
  options: IAppLauncherOption[];
}

export interface IAppLauncherAppIcon {
  uri?: string;
  color?: string;
  type?: IconType;
  name?: string;
  fontClass?: string;
}

export interface IAppLauncherOption {
  label: string;
  uri: string;
  priority?: number;
  icon?: IAppLauncherAppIcon;
  helpText?: string;
  target?: string;
  iconURI?: string;
}

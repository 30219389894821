import { getLightElement, notChildEventListener, getActiveElement } from '@tylertech/forge-core';
import { APP_LAUNCHER_CONSTANTS } from '../app-launcher-constants';
import { IAppLauncherComponent } from '../app-launcher';
import { IAppLauncherButtonComponent } from './app-launcher-button';
import { IAppLauncherConfig, APP_LAUNCHER_BUTTON_CONSTANTS } from './app-launcher-button-constants';
import { BaseAdapter, IBaseAdapter, IPopoverComponent } from '@tylertech/forge';

export interface IAppLauncherButtonAdapter extends IBaseAdapter {
  initialize(): void;
  setClickListener(listener: (evt: Event) => void): void;
  removeClickListener(listener: (evt: Event) => void): void;
  requestFocus(): void;
  open(appLauncherConfig: IAppLauncherConfig, dismissListener: () => void): () => void;
  close(): void;
  setAppLauncherConfig(config: Partial<IAppLauncherConfig>): void;
  setAppLauncherFocus(): void;
}

export class AppLauncherButtonAdapter extends BaseAdapter<IAppLauncherButtonComponent> implements IAppLauncherButtonAdapter {
  private _buttonElement: HTMLButtonElement;
  private _popoverElement: IPopoverComponent;
  private _appLauncher: IAppLauncherComponent;

  constructor(component: IAppLauncherButtonComponent) {
    super(component);
  }

  public initialize(): void {
    this._buttonElement = getLightElement(this._component, APP_LAUNCHER_BUTTON_CONSTANTS.selectors.BUTTON) as HTMLButtonElement;
  }

  public setClickListener(listener: (evt: Event) => void): void {
    this._buttonElement.addEventListener('click', listener);
  }

  public removeClickListener(listener: (evt: Event) => void): void {
    this._buttonElement.removeEventListener('click', listener);
  }

  public requestFocus(): void {
    this._buttonElement.focus();
  }

  public open(appLauncherConfig: IAppLauncherConfig, dismissListener: () => void): () => void {
    this._appLauncher = document.createElement(APP_LAUNCHER_CONSTANTS.elementName) as IAppLauncherComponent;
    this.setAppLauncherConfig(appLauncherConfig);
    this._appLauncher.addEventListener(APP_LAUNCHER_CONSTANTS.events.SELECT, (evt: CustomEvent) => {
      evt.stopPropagation();

      dismissListener();

      const event = new CustomEvent(APP_LAUNCHER_CONSTANTS.events.SELECT, { detail: evt.detail });
      this.dispatchHostEvent(event);
      if (event.defaultPrevented) {
        evt.preventDefault();
      }
    });

    this._popoverElement = document.createElement('forge-popover');
    this._popoverElement.placement = 'bottom-end';
    this._popoverElement.anchorElement = this._buttonElement;
    this._popoverElement.persistent = true;
    this._popoverElement.appendChild(this._appLauncher);
    
    document.body.appendChild(this._popoverElement);
    this._popoverElement.open = true;

    return notChildEventListener(this._popoverElement, _activeElement => {
      if (!this._popoverElement) {
        return;
      }
      if (!this._component.contains(getActiveElement())) {
        dismissListener();
      }
    }, true);
  }

  public close(): void {
    if (this._popoverElement) {
      this._popoverElement.open = false;
      this._popoverElement.remove();
    }
    if (this._appLauncher) {
      this._appLauncher = undefined as any;
    }
  }

  public setAppLauncherConfig(config: Partial<IAppLauncherConfig>): void {
    if (this._appLauncher) {
      Object.assign(this._appLauncher, config);
    }
  }

  public setAppLauncherFocus(): void {
    if (this._appLauncher) {
      this._appLauncher.focusFirst();
    }
  }
}

import { getShadowElement, toggleClass } from '@tylertech/forge-core';
import { BaseAdapter, IBaseAdapter } from '@tylertech/forge';
import { FOOTER_CONSTANTS } from './footer-constants';
import { IFooterComponent } from './footer';

export interface IFooterAdapter extends IBaseAdapter {
  setRole(): void;
  setAlternativeLayout(value: boolean): void;
}

export class FooterAdapter extends BaseAdapter<IFooterComponent> implements IFooterAdapter {
  private _footerElement: HTMLElement;

  constructor(component: IFooterComponent) {
    super(component);
    this._footerElement = getShadowElement(component, FOOTER_CONSTANTS.selectors.FOOTER);
  }

  public setRole(): void {
    if (!this._component.hasAttribute('role')) {
      this._component.setAttribute('role', FOOTER_CONSTANTS.strings.ROLE);
    }
  }

  public setAlternativeLayout(value: boolean): void {
    toggleClass(this._footerElement, value, FOOTER_CONSTANTS.classes.ALTERNATIVE_LAYOUT);
  }
}

import { getEventPath, ICustomElementCore } from '@tylertech/forge-core';
import { ILandingPageLayoutAdapter } from './landing-page-layout-adapter';
import { LandingPageLayoutAlignment, LandingPageLayoutMode, LandingPageLayoutSize, LANDING_PAGE_LAYOUT_CONSTANTS } from './landing-page-layout-constants';

export interface ILandingPageLayoutCore extends ICustomElementCore {
  mode: LandingPageLayoutMode;
}

export class LandingPageLayoutCore implements ILandingPageLayoutCore {
  private _mode: LandingPageLayoutMode = 'two-third';
  private _alignment: LandingPageLayoutAlignment = 'center';
  private _size: LandingPageLayoutSize = 'normal';
  private _topSlotListener: EventListener;
  private _announcementsSlotListener: EventListener;
  private _imageUrlLarge: string;
  private _imageUrlSmall: string;

  constructor(private _adapter: ILandingPageLayoutAdapter) {
    this._topSlotListener = event => this._onTopSlotChange(event);
    this._announcementsSlotListener = event => this._onAnnouncementsSlotChange(event);
  }

  public initialize(): void {
    this._adapter.initialize();
    this._applyMode();
    this._applyAlignment();
    this._setupSlots();
    this._applySize();
  }

  public disconnect(): void {
    this._cleanupSlots();
  }

  public get mode(): LandingPageLayoutMode {
    return this._mode;
  }
  public set mode(value: LandingPageLayoutMode) {
    if (this._mode !== value) {
      this._mode = value;
      this._applyMode();
    }
  }

  public get alignment(): LandingPageLayoutAlignment {
    return this._alignment;
  }
  public set alignment(value: LandingPageLayoutAlignment) {
    if (value !== this._alignment) {
      this._alignment = value;
      this._applyAlignment();
    }
  }

  public get size(): LandingPageLayoutSize {
    return this._size;
  }
  public set size(value: LandingPageLayoutSize) {
    if (value !== this._size) {
      this._size = value;
      this._applySize();
    }
  }


  public get imageUrlLarge(): string {
    return this._imageUrlLarge;
  }
  public set imageUrlLarge(value: string) {
    if (value !== this._imageUrlLarge) {
      this._imageUrlLarge = value;
      this._applyImageUrlLarge();
    }
  }

  public get imageUrlSmall(): string {
    return this._imageUrlSmall;
  }
  public set imageUrlSmall(value: string) {
    if (value !== this._imageUrlSmall) {
      this._imageUrlSmall = value;
      this._applyImageUrlSmall();
    }
  }

  private _applyAlignment(): void {
    this._adapter.setHostAttribute(LANDING_PAGE_LAYOUT_CONSTANTS.attributes.ALIGNMENT, this._alignment);
    this._adapter.setAlignment(this._alignment);
  }

  private _applyMode(): void {
    this._adapter.setHostAttribute(LANDING_PAGE_LAYOUT_CONSTANTS.attributes.MODE, this._mode);
    this._adapter.setMode(this._mode);
  }

  private _applySize(): void {
    this._adapter.setHostAttribute(LANDING_PAGE_LAYOUT_CONSTANTS.attributes.SIZE, this._size);
    this._adapter.setSize(this._size);
  }

  private _setupSlots(): void {
    this._adapter.addTopSlotListener('slotchange', this._topSlotListener);
    this._adapter.addAnnouncementsSlotListener('slotchange', this._announcementsSlotListener);
  }

  private _cleanupSlots(): void {
    this._adapter.removeTopSlotListener('slotchange', this._topSlotListener);
    this._adapter.removeAnnouncementsSlotListener('slotchange', this._announcementsSlotListener);
  }

  private _onTopSlotChange(event: Event): void {
    const slotElement = getEventPath(event)[0] as HTMLSlotElement;
    this._adapter.toggleBodyOnly(slotElement.assignedElements().length > 0);
  }

  private _onAnnouncementsSlotChange(event: Event): void {
    const slotElement = getEventPath(event)[0] as HTMLSlotElement;
    this._adapter.toggleHeaderAlignment(slotElement.assignedElements().length > 0);
  }

  private _applyImageUrlSmall(): void {
    this._adapter.setHostAttribute(LANDING_PAGE_LAYOUT_CONSTANTS.attributes.IMAGE_URL_SMALL, this._imageUrlSmall);
    this._adapter.setSmallImageSource(this._imageUrlSmall);
  }

  private _applyImageUrlLarge(): void {
    this._adapter.setHostAttribute(LANDING_PAGE_LAYOUT_CONSTANTS.attributes.IMAGE_URL_LARGE, this._imageUrlLarge);
    this._adapter.setLargeImageSource(this._imageUrlLarge);
  }
}

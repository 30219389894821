import { COMPONENT_NAME_PREFIX } from '../constants';

const elementName = `${COMPONENT_NAME_PREFIX}landing-page-layout`;
const component = 'forge-landing-page-layout';
const root = `${component}__root`;

const classes = {
  LAYOUT_BODY: `${component}__body`,
  LAYOUT_BODY_TWO_THIRD: `${component}__body--two-third`,
  LAYOUT_BODY_EQUAL: `${component}__body--equal`,
  LAYOUT_BODY_THREE: `${component}__body--three`,
  LAYOUT_BODY_SINGLE: `${component}__body--single`,
  LAYOUT_BODY_ONLY: `${component}__body--only`,
  LAYOUT_ALIGNMENT_LEFT: `${component}--align-left`,
  LAYOUT_ALIGNMENT_CENTER: `${component}--align-center`,
  WIDE_WIDTH_MODE: `${component}--wide`,
  EMPTY_ANNOUNCEMENTS: `${component}__header__body--empty-announcements`,
  BACKROUND_PICTURE: `${component}__header__background__picture`,
  BACKROUND_PICTURE_IMAGE_SMALL: `${component}__header__background__picture__image-small`,
  BACKROUND_PICTURE_IMAGE_LARGE: `${component}__header__background__picture__image-large`,
  LAYOUT_FOOTER: `${component}__footer`,
  ROOT: root
};

const selectors = {
  VIEW_BODY: `.${classes.LAYOUT_BODY}`,
  component: `.${component}`,
  HEADER_BODY: `.${component}__header__body`,
  TOP_SLOT: 'slot[name="top"]',
  ANNOUNCEMENTS_SLOT: 'slot[name="announcements"]',
  BACKROUND_PICTURE: `.${classes.BACKROUND_PICTURE}`,
  ROOT: `.${root}`
};

const attributes = {
  MODE: 'mode',
  ALIGNMENT: 'alignment',
  SIZE: 'size',
  IMAGE_URL_SMALL: 'image-url-small',
  IMAGE_URL_LARGE: 'image-url-large'
};

const strings = {
  MOBILE_IMG_MEDIA: '(max-width: 768px)',
  DESKTOP_IMG_MEDIA: '(min-width: 769px)'
};

export const LANDING_PAGE_LAYOUT_CONSTANTS = {
  elementName,
  classes,
  attributes,
  selectors,
  strings
};

export type LandingPageLayoutMode = 'two-third' | 'equal' | 'three' | 'single';
export type LandingPageLayoutAlignment = 'left' | 'center';
export type LandingPageLayoutSize = 'normal' | 'wide';

import {
  BaseAdapter,
  IBaseAdapter,
  ICON_CLASS_NAME,
  IViewSwitcherComponent,
  VIEW_SWITCHER_CONSTANTS
} from '@tylertech/forge';
import { getShadowElement, removeAllChildren, removeElement } from '@tylertech/forge-core';
import { IAppLauncherComponent } from './app-launcher';
import { AppLauncherView, APP_LAUNCHER_CONSTANTS, IAppLauncherOption } from './app-launcher-constants';

export interface IAppLauncherAdapter extends IBaseAdapter {
  setView(view: AppLauncherView): void;
  setOptions(options: IAppLauncherOption[], listener: (option: IAppLauncherOption) => void): void;
  focusFirstOption(): void;
  addInputListener(type: string, listener: (evt: Event) => void): void;
  removeInputListener(type: string, listener: (evt: Event) => void): void;
  setTitle(value: string): void;
  hideSearch(): void;
}

export class AppLauncherAdapter extends BaseAdapter<IAppLauncherComponent> implements IAppLauncherAdapter {
  private _rootElement: HTMLElement;
  private _viewSwitcher: IViewSwitcherComponent;
  private _optionsContainer: HTMLElement;
  private _inputElement: HTMLInputElement;
  private _titleElement: HTMLElement;
  private _searchContainer: HTMLElement;

  constructor(component: IAppLauncherComponent) {
    super(component);
    this._rootElement = getShadowElement(this._component, APP_LAUNCHER_CONSTANTS.selectors.ROOT);
    this._viewSwitcher = getShadowElement(this._component, VIEW_SWITCHER_CONSTANTS.elementName) as IViewSwitcherComponent;
    this._optionsContainer = getShadowElement(this._component, APP_LAUNCHER_CONSTANTS.selectors.OPTIONS_CONTAINER);
    this._inputElement = getShadowElement(this._component, APP_LAUNCHER_CONSTANTS.selectors.SEARCH_INPUT) as HTMLInputElement;
    this._titleElement = getShadowElement(this._component, APP_LAUNCHER_CONSTANTS.selectors.TITLE);
    this._searchContainer = getShadowElement(this._component, APP_LAUNCHER_CONSTANTS.selectors.SEARCH_CONTAINER);
  }

  public setView(view: AppLauncherView): void {
    this._viewSwitcher.index = view;
  }

  public setOptions(options: IAppLauncherOption[], listener: (option: IAppLauncherOption) => void): void {
    this._setOptions(options, this._optionsContainer, listener);
  }

  private _setOptions(options: IAppLauncherOption[], container: HTMLElement, listener: (option: IAppLauncherOption) => void): void {
    const gridElement = container.querySelector(APP_LAUNCHER_CONSTANTS.selectors.GRID) as HTMLElement;
    removeAllChildren(gridElement);

    for (const option of options) {
      const element = this._createOption(option, listener);
      gridElement.appendChild(element);
    }
  }

  private _createOption(option: IAppLauncherOption, listener: (option: IAppLauncherOption) => void): HTMLElement {
    const container = document.createElement('div');
    container.setAttribute('part', 'default-view-grid-item');
    container.classList.add(APP_LAUNCHER_CONSTANTS.classes.GRID_ITEM);
    container.tabIndex = 0;
    container.setAttribute('role', 'button');
    container.addEventListener('click', () => listener(option));
    container.addEventListener('keydown', evt => {
      if (evt.key === 'Enter') {
        listener(option);
      }
    });

    const stateLayer = document.createElement('forge-state-layer');
    stateLayer.targetElement = container;
    container.appendChild(stateLayer);

    const focusIndicator = document.createElement('forge-focus-indicator');
    focusIndicator.inward = true;
    focusIndicator.targetElement = container;
    container.appendChild(focusIndicator);

    let iconElement: HTMLElement | HTMLImageElement;
    if (option.icon && (option.icon.name || option.icon.uri)) {
      const type = option.icon.type || '';
      switch (type.toLowerCase()) {
        case 'svg':
        case 'image':
          iconElement = this._createImageIcon(option.icon.uri as string);
          break;
        case 'font':
        default:
          iconElement = this._createFontIcon(option.icon.name, option.icon.color, option.icon.fontClass);
          break;
      }
    } else if (option.iconURI) {
      if (option.iconURI.lastIndexOf('.') >= 0) {
        iconElement = this._createImageIcon(option.iconURI);
      } else {
        iconElement = this._createFontIcon(option.iconURI);
      }
    } else {
      iconElement = this._createFontIcon();
    }
    container.appendChild(iconElement);

    const label = document.createElement('div');
    label.classList.add(APP_LAUNCHER_CONSTANTS.classes.GRID_ITEM_TITLE);
    label.textContent = option.label;
    container.appendChild(label);

    return container;
  }

  private _createFontIcon(name?: string, color?: string, fontClass?: string): HTMLElement {
    const avatarEl = document.createElement('forge-avatar');

    if (color) {
      avatarEl.style.setProperty('--forge-avatar-background', color);
    }
    
    const iEl = document.createElement('i');
    iEl.classList.add(fontClass ? fontClass : ICON_CLASS_NAME);
    iEl.textContent = name || APP_LAUNCHER_CONSTANTS.strings.DEFAULT_FONT_ICON_NAME;
    avatarEl.appendChild(iEl);

    return avatarEl;
  }

  private _createImageIcon(uri: string): HTMLImageElement {
    const img = document.createElement('img');
    img.setAttribute('part', 'default-view-grid-item-image');
    img.src = uri;
    return img;
  }

  public focusFirstOption(): void {
    if (this._inputElement.isConnected) {
      this._inputElement.focus();
    } else {
      const gridItems = Array.from(this._rootElement.querySelectorAll(APP_LAUNCHER_CONSTANTS.selectors.GRID_ITEM)) as HTMLElement[];
      if (gridItems && gridItems.length) {
        gridItems[0].focus();
      }
    }
  }

  public addInputListener(type: string, listener: (evt: Event) => void): void {
    this._inputElement.addEventListener(type, listener);
  }

  public removeInputListener(type: string, listener: (evt: Event) => void): void {
    this._inputElement.removeEventListener(type, listener);
  }

  public setTitle(value: string): void {
    this._titleElement.textContent = value;
  }

  public hideSearch(): void {
    removeElement(this._searchContainer);
  }
}

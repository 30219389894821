import { ICustomElementCore } from '@tylertech/forge-core';
import { IFooterAdapter } from './footer-adapter';
import { FOOTER_CONSTANTS, FooterLayout } from './footer-constants';

export interface IFooterCore extends ICustomElementCore {
  layout: FooterLayout;
  layoutBreakpoint: number;
}

export class FooterCore implements IFooterCore {
  private _layout = FOOTER_CONSTANTS.strings.DEFAULT_LAYOUT;
  private _layoutBreakpoint = FOOTER_CONSTANTS.numbers.LAYOUT_BREAKPOINT;
  private _mediaQuery: MediaQueryList;
  private _mediaChangeHandler: (evt: MediaQueryList | MediaQueryListEvent) => void;

  constructor(public _adapter: IFooterAdapter) {
    this._mediaChangeHandler = (evt: MediaQueryList | MediaQueryListEvent) => this._onMediaChange(evt);
  }

  public initialize(): void {
    this._applyLayout();
    this._adapter.setRole();
  }

  public disconnect(): void {
    this._removeMediaQuery();
  }

  /** Gets/sets the layout mode */
  public get layout(): FooterLayout {
    return this._layout as FooterLayout;
  }
  public set layout(value: FooterLayout) {
    if (this._layout !== value) {
      this._layout = value;
      this._applyLayout();
    }
  }

  /** Gets/sets the breakpoint for automatic layout switching */
  public get layoutBreakpoint(): number {
    return this._layoutBreakpoint;
  }
  public set layoutBreakpoint(value: number) {
    if (this._layoutBreakpoint !== value) {
      this._layoutBreakpoint = value;
      this._applyLayoutBreakpoint();
    }
  }

  private _applyLayout(): void {
    if (this._layout === 'auto') {
      this._setMediaQuery();
      this._adapter.setHostAttribute(FOOTER_CONSTANTS.attributes.LAYOUT, 'auto');
      return;
    }

    this._removeMediaQuery();

    if (this._layout === 'alternative') {
      this._adapter.setHostAttribute(FOOTER_CONSTANTS.attributes.LAYOUT, 'alternative');
      this._adapter.setAlternativeLayout(true);
    } else if (this._layout === 'standard') {
      this._adapter.setHostAttribute(FOOTER_CONSTANTS.attributes.LAYOUT, 'standard');
      this._adapter.setAlternativeLayout(false);
    }
  }

  private _applyLayoutBreakpoint(): void {
    this._adapter.setHostAttribute(FOOTER_CONSTANTS.attributes.LAYOUT_BREAKPOINT, this._layoutBreakpoint.toString());

    if (this._layout === 'auto') {
      this._removeMediaQuery();
      this._setMediaQuery();
    }
  }

  private _setMediaQuery(): void {
    this._mediaQuery = window.matchMedia(`(max-width: ${this._layoutBreakpoint}px)`);
    this._mediaQuery.addEventListener('change', this._mediaChangeHandler);
    this._onMediaChange(this._mediaQuery);
  }

  private _removeMediaQuery(): void {
    if (this._mediaQuery) {
      this._mediaQuery.removeEventListener('change', this._mediaChangeHandler);
    }
  }

  private _onMediaChange(evt: MediaQueryList | MediaQueryListEvent): void {
    this._adapter.setAlternativeLayout(evt.matches);
  }
}

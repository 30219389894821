import { ICustomElementCore, isFunction } from '@tylertech/forge-core';
import { IAppLauncherAdapter } from './app-launcher-adapter';
import { IAppLauncherOption, AppLauncherView, AppLauncherOptionsCallback, APP_LAUNCHER_CONSTANTS } from './app-launcher-constants';

export interface IAppLauncherCore extends ICustomElementCore {
  optionsCallback: AppLauncherOptionsCallback;
  focusFirst(): void;
}

export class AppLauncherCore implements IAppLauncherCore {
  private _options: IAppLauncherOption[] = [];
  private _optionsCallback: AppLauncherOptionsCallback;
  private _selectListener: (option: IAppLauncherOption) => void;
  private _searchListener: (evt: KeyboardEvent) => void;

  constructor(private _adapter: IAppLauncherAdapter) {
    this._selectListener = option => this._onSelect(option);
    this._searchListener = evt => this._onSearch(evt);
  }

  public initialize(): void {
    this._fetchOptions();
    this._adapter.addInputListener('input', this._searchListener);
  }

  public disconnect(): void {
    this._adapter.removeInputListener('input', this._searchListener);
  }

  public focusFirst(): void {
    this._adapter.focusFirstOption();
  }

  private async _fetchOptions(): Promise<void> {
    if (!isFunction(this._optionsCallback)) {
      throw new Error('Invalid options callback provided.');
    }
    this._adapter.setView(AppLauncherView.Loading);
    try {
      const val = this._optionsCallback();
      const result = await Promise.resolve(val);
      if (result && result.options && result.options.length) {
        this._options = result.options;
        if (this._options.length <= APP_LAUNCHER_CONSTANTS.numbers.MIN_OPTIONS_FOR_SEARCH) {
          this._adapter.hideSearch();
        }
        this._adapter.setOptions(result.options || [], this._selectListener);
        this._adapter.setView(AppLauncherView.Default);
      } else {
        this._adapter.setView(AppLauncherView.Empty);
      }
    } catch (e) {
      console.error(e);
      this._adapter.setView(AppLauncherView.Error);
    }
  }

  private _onSelect(option: IAppLauncherOption): void {
    const isCancelled = !this._adapter.emitHostEvent(APP_LAUNCHER_CONSTANTS.events.SELECT, option);
    if (!isCancelled) {
      this._openUri(option.uri, option.target || APP_LAUNCHER_CONSTANTS.strings.DEFAULT_OPEN_TARGET);
    }
  }

  private _onSearch(evt: KeyboardEvent): void {
    evt.stopPropagation();
    const value = (evt.target as HTMLInputElement).value.trim().toLowerCase();
    const filteredOptions = value ?
      this._options.filter(o => {
                              const matchesLabel = o.label.trim().toLowerCase().includes(value);
                              const matchesHelpText = o.helpText && o.helpText.trim().toLowerCase().includes(value);
                              return matchesLabel || matchesHelpText;
                            }) :
      this._options;

    this._adapter.setOptions(filteredOptions, this._selectListener);

    if (value) {
      this._adapter.setTitle(filteredOptions.length ? 'Results' : 'No apps found');
    } else {
      this._adapter.setTitle('My apps');
    }
  }

  private _openUri(uri: string, target: string): void {
    if (!uri) {
      throw new Error('Invalid URI provided.');
    }
    window.open(uri, target);
  }

  public get optionsCallback(): AppLauncherOptionsCallback {
    return this._optionsCallback;
  }
  public set optionsCallback(cb: AppLauncherOptionsCallback) {
    this._optionsCallback = cb;
  }
}
